<template>
  <v-row>
    <v-col class="pa-0" cols="12">
      <v-toolbar class="px-0 transparent" flat height="45">
        <v-toolbar-title>
          <v-hover v-slot:default="{ hover }">
            <v-btn class="subtitle-1 font-weight-medium" :class="{ 'text--primary': !hover }" :to="{ name: 'ComplianceProcessList' }" color="primary" height="24" text>Cuadraturas</v-btn>
          </v-hover>
          <v-icon class="mx-2" color="fontBody">mdi-chevron-right</v-icon>
          <v-hover v-slot:default="{ hover }">
            <v-btn class="subtitle-1 font-weight-medium" :class="{ 'text--primary': !hover }" :to="{ name: 'ComplianceProcessDetail' }" color="primary" height="24" text>Tienda 24 - 2023/11/12</v-btn>
          </v-hover>
          <v-icon class="mx-2" color="fontBody">mdi-chevron-right</v-icon>
          <span class="subtitle-1 text--secondary">Documentos</span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center">
            <v-btn class="mx-1" outlined :ripple="false"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
            <v-btn class="mx-1 mr-3" color="primary">Exportar registros<v-icon right>mdi-download</v-icon></v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>

    <v-col class="px-0" cols="12">
      <v-card class="mt-4" flat>
        <v-card-title class="pa-0">
          <v-list dense color="transparent">
            <v-list-item>
              <v-list-item-icon class="mx-4">
                <v-icon color="primary" size="24">mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="body-1 fontBody--text font-weight-medium text-truncate">Real Plaza S.R.L.<span class="ml-3 body-2 fontDraw--text">RUC 20547141068</span></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="text-right">
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-data-table class="table-not-rounded" dense :headers="headers" :items="itemsTable" @click:row="(itemsTable) => { currentItem = itemsTable; modalRedirect = true }" :items-per-page="pagination" hide-default-header disable-sort mobile-breakpoint="300" hide-default-footer style="cursor: pointer">
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" @sort="setSort" />
              <v-divider class="mt-n1 ml-n1 position-absolute" style="min-width: 100%; z-index: 2" />
            </template>

            <template v-slot:[`item.document_type`]="{item}">
              <span class="body-1 text--secondary">{{item.document_type}}</span>
            </template>

            <template v-slot:[`item.serie`]="{item}">
              <span class="body-1 text--secondary">{{item.serie}}</span>
            </template>

            <template v-slot:[`item.number`]="{item}">
              <span class="body-1 font-weight-medium">{{item.number}}</span>
            </template>

            <template v-slot:[`item.total`]="{item}">
              <span class="body-1 font-weight-medium">{{item.total | number}}</span><span class="mx-2 body-2">PEN</span>
            </template>

            <template v-slot:[`item.pse`]="{item}">
              <v-icon v-if="!item.pse.length">mdi-minus</v-icon>
              <v-btn v-else text @click.stop="item.pse === 'refused' ? handleErrorInf(item) : ''" :ripple="false">
                <v-icon :color="item.pse === 'check' ? 'success' : 'error'" size="22">{{item.pse === 'check' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.ose`]="{item}">
              <v-icon v-if="!item.ose.length">mdi-minus</v-icon>
              <v-btn v-else text @click.stop="item.ose === 'refused' ? handleErrorInf(item) : ''" :ripple="false">
                <v-icon :color="item.ose === 'check' ? 'success' : 'error'" size="22">{{item.ose === 'check' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.sunat`]="{item}">
              <v-icon v-if="!item.sunat.length">mdi-minus</v-icon>
              <v-btn v-else text @click.stop="item.sunat === 'refused' ? handleErrorInf(item) : ''" :ripple="false">
                <v-icon :color="item.sunat === 'check' ? 'success' : 'error'" size="22">{{item.sunat === 'check' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <VMainPagination titleShowAll="Ver todos los procesos" :count="1238" :pageSize="20" redirect="ComplianceProcessList"  />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import VMainPagination from '@/components/commons/VMainPagination'
import VTableHeaders from '@/components/VTable/VTableHeaders'

import ListViewMixin from '@/mixins/ListViewMixin'

export default {
  components: {
    VMainPagination,
    VTableHeaders
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    currentItem: {},
    modalRedirect: false,
    modalErrorInf: false,
    count: 1238,
    selectedItem: 0,
    search: '',
    list: [
      {
        text: 'Empresas',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Interbank',
            id: '201000053455'
          },
          {
            name: 'Intercorp Retail',
            id: '201000053458'
          },
          {
            name: 'Casa Andina',
            id: '201000053459'
          },
          {
            name: 'Supermercados Peruanos',
            id: '201000053429'
          },
          {
            name: 'Tiendas Peruanos',
            id: '201000053422'
          },
          {
            name: 'Homecenters Peruanos',
            id: '201000053412'
          },
          {
            name: 'Financiera Oh!',
            id: '201000053312'
          }
        ]
      },
      {
        text: 'Tipos de documentos',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Boleta electrónica',
            id: '01'
          },
          {
            name: 'Factura electrónica',
            id: '02'
          },
          {
            name: 'Nota de crédito',
            id: '03'
          },
          {
            name: 'Nota de debito',
            id: '04'
          },
          {
            name: 'Comprobante de retención',
            id: '05'
          },
          {
            name: 'Comprobante de percepción',
            id: '06'
          },
          {
            name: 'D.autorizado electrónico',
            id: '07'
          },
          {
            name: 'Guías de Remisión',
            id: '08'
          }
        ]
      },
      {
        text: 'Orígenes',
        disabled: true,
        active: false,
        items: [
          {
            name: 'POS',
            id: '01'
          },
          {
            name: 'B.O',
            id: '02'
          },
          {
            name: 'CD',
            id: '03'
          },
          {
            name: 'E-Com',
            id: '04'
          }
        ]
      },
      {
        text: 'Problemas',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Problema 1',
            id: '01'
          },
          {
            name: 'Problema 2',
            id: '02'
          },
          {
            name: 'Problema 3',
            id: '03'
          },
          {
            name: 'Problema 4',
            id: '04'
          }
        ]
      }
    ],
    chartData: {
      labels: ['En cumplimiento', 'Con problemas', 'No consultados'],
      datasets: [
        {
          data: [85, 15, 0]
        }
      ]
    },
    chartData1: {
      labels: ['POS', 'B.O.', 'CD', 'E-Com'],
      datasets: [
        {
          data: [60, 15, 10, 15]
        }
      ]
    },
    itemsTable: [
      { document_type: 'Factura electrónica', serie: 'F039', number: '12345678', total: 565432, origen: 'POS', pse: 'check', ose: 'check', sunat: 'check' },
      { document_type: 'Boleta electrónica', serie: 'B011', number: '12345678', total: 565432, origen: 'B.O.', pse: 'check', ose: 'check', sunat: 'refused' },
      { document_type: 'Boleta electrónica', serie: 'B001', number: '12345678', total: 565432, origen: 'CD', pse: 'check', ose: 'refused', sunat: '' },
      { document_type: 'Boleta electrónica', serie: 'B023', number: '12345678 ', total: 565432, origen: 'B.O.', pse: 'check', ose: 'refused', sunat: 'check' },
      { document_type: 'Factura electrónica', serie: 'F039', number: '12345678', total: 565432, origen: 'POS', pse: 'refused', ose: '', sunat: '' },
      { document_type: 'Boleta electrónica', serie: 'B011', number: '12345678', total: 565432, origen: 'B.O.', pse: 'check', ose: 'check', sunat: 'refused' },
      { document_type: 'Boleta electrónica', serie: 'B001', number: '12345678', total: 565432, origen: 'CD', pse: 'check', ose: 'refused', sunat: '' },
      { document_type: 'Boleta electrónica', serie: 'B023', number: '12345678 ', total: 565432, origen: 'B.O.', pse: 'check', ose: 'check', sunat: 'check' },
      { document_type: 'Boleta electrónica', serie: 'B023', number: '12345678 ', total: 565432, origen: 'B.O.', pse: 'refused', ose: '', sunat: '' }
    ],
    headers: [
      { text: 'Tipo de documento', value: 'document_type', sortable: false },
      { text: 'Serie', value: 'serie', sortable: false, align: 'end' },
      { text: 'Correlativo', value: 'number', sortable: false, align: 'end' },
      { text: 'Importe total', value: 'total', sortable: false, align: 'end' },
      { text: 'Axteroid', value: 'pse', align: 'center', sortable: false, width: 140 },
      { text: 'OSE', value: 'ose', align: 'center', sortable: false, width: 140 },
      { text: 'SUNAT', value: 'sunat', align: 'center', sortable: false, width: 140 }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  methods: {
    getCurrent () {
      //
    },
    handleModalRedirect () {
      this.modalRedirect = false
      this.$router.push({name: 'DocumentsRetrieve', params: {id: 'doc_1H5BlmXOI0B6UevnDi' }}).catch(() => {})
    },
    handleErrorInf (item = {}) {
      this.modalErrorInf = true
    }
  }
}
</script>